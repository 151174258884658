<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'user-manage',
  data() {
    return {
      editable: true,
      tab: 'authGroup',
      tabItems: [
        { name: 'authGroup', icon: 'groups', label: '권한 관리', component: () => import(`${'./authGroup.vue'}`) },
        { name: 'authGroupMenu', icon: 'list', label: '권한별 메뉴', component: () => import(`${'./authGroupMenu.vue'}`) },
        { name: 'authGroupUser', icon: 'manage_accounts', label: '권한별 부서/사용자', component: () => import(`${'./authGroupUser.vue'}`) },
        { name: 'authGroupByUser', icon: 'badge', label: '부서/사용자별 권한', component: () => import(`${'./authGroupByUser.vue'}`) },
        { name: 'authPlant', icon: 'home_work', label: '부서/사용자별 사업장권한', component: () => import(`${'./authGroupPlant.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
